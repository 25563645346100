import Vue from 'vue'

class IssueRepository {

  stats(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/issue/stats',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  search(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/issue/search',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  create(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/issue',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  update(id, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/issue/' + id,
        method: 'put',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/issue/' + id,
        method: 'delete',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/issue/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  export(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/issue/export',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  changePriority(id, priority) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/issue/' + id + '/priority',
        method: 'put',
        data: {
          priority,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  addEvent(id, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/issue/' + id + '/event',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default new IssueRepository()