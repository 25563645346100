<template>
  <div class="pa-10">
    <title-page icon="mdi-alert-outline">
      {{ $t('issues.issues') }}
    </title-page>


    <v-row v-if="currentUser.permissions.includes('ISSUE_WRITE')">
      <v-col class="text-right">
        <v-btn :to="{name: 'issues.add'}">
          <v-icon left>mdi-alert-plus-outline</v-icon>
          {{ $t('issues.add')}}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <date-picker
            :label="$t('period')"
            range
            v-model="periods"
            clearable
        />
      </v-col>
    </v-row>

    <v-row v-if="stats">
      <template v-for="(type, i) in issueTypes">
        <v-col md="4"  v-if="type.value" :key="'type-' + i">
          <v-card class="issues-counter" :color="type.count > 0 ? 'red' : 'green'" @click.prevent="filters.types = [type.value]">
            <v-card-title>
              {{ type.text }}
            </v-card-title>
            <v-card-text>
              <div class="counter">
                {{ type.count }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>

    <v-divider class="my-6"/>

    <v-card>
      <v-card-text>
        <v-form @submit.prevent="loadData">
          <v-row>
            <v-col md="3">
              <v-select
                  ref="selectTypes"
                  :label="$t('issues.type')"
                  v-model="filters.types"
                  :items="issueTypes"
                  multiple
              >

              </v-select>
            </v-col>


            <v-col md="3">
              <v-select
                  ref="selectStatus"
                  :label="$t('issues.status')"
                  v-model="filters.statues"
                  multiple
                  :items="statusTypes"
              >
              </v-select>
            </v-col>


            <v-col md="3">
              <v-select
                  ref="selectPriorities"
                  :label="$t('issues.priority')"
                  v-model="filters.priorities"
                  multiple
                  :items="priorityTypes"
              >
              </v-select>
            </v-col>


            <v-col md="3">
              <tree-select-organization
                  :label="$t('organization-id')"
                  clearable
                  v-model="filters.organizationId"
              />
            </v-col>
            <v-col md="3">
              <textfield
                  :label="$t('charging-point-id')"
                  clearable
                  v-model="filters.chargingPointId"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-divider class="my-6"/>

    <v-row>
      <v-col >
        <v-data-table
          :headers="headers"
          :items="issues"
          :options.sync="options"
          :loading="loading"
          :server-items-length="total"
        >
          <template #item.type="{value}">
            {{ $t('enums.issue-types.' + value)}}
          </template>

          <template #item.evse.chargingPointIdentifier="{item}">
            <router-link :to="{name: 'charging-points.detail',params: {id: item.evse.chargingPointId} }" target="_blank">
              {{ item.evse.chargingPointIdentifier }}
            </router-link>
          </template>


          <template #item.status="{value}">
            {{ $t('enums.issue-statues.' + value)}}
          </template>


          <template #item.priority="{value, item}">
            <span :style="{color: issuePriorityColor(item)}">
              {{ $t('enums.issue-priorities.' + value)}}
            </span>
          </template>

          <template #item.subject="{item}">
            <router-link :to="{name: 'issues.detail',params: {id: item.id} }">
              {{ issueSubject(item) }}
            </router-link>
          </template>

          <template #item.createdBy="{item}">
            {{ item.createdBy ? `${item.createdBy.firstname} ${item.createdBy.lastname}` : $t('automation') }}<br />
            {{ item.createdAt|formatDate }}
          </template>


        </v-data-table>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import dayjs from "dayjs";
import IssueRepository from "@repository/IssueRepository";
import TreeSelectOrganization from "@blocks/Select/TreeSelectOrganization.vue";

export default {
  name: 'Issues',
  components: {TreeSelectOrganization},
  data() {

    let dates = [
      dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      dayjs().format('YYYY-MM-DD'),
    ]

    return {
      loading: false,
      periods: dates,
      filters: {
        organizationId: null,
        chargingPointId: null,
        statues: [],
        types: [],
        priorities: [],
        dates,
      },
      total: 0,
      issues: [],
      options: null,
      stats: null,
    }
  },

  mounted() {
    this.loadStats()
  },

  watch: {
    filters: {
      handler() {
        if(this.options.page > 1) {
          this.options.page = 1
        } else {
          this.loadData()
          this.loadStats()
        }
      },
      deep: true
    },

    options() {
      this.loadData()
    },

    periods(val) {
      if(val?.length === 2) {
        this.filters.dates = val
      } else {
        this.filters.dates = []
      }
      if(this.options.page > 1) {
        this.options.page = 1
      } else {
        this.loadData()
      }
    }
  },

  computed: {

    headers() {
      return [
        {text: this.$t('status'), value: 'status'},
        {text: this.$t('type'), value: 'type'},
        {text: this.$t('issues.subject'), value: 'subject'},
        {text: this.$t('issues.priority'), value: 'priority'},
        {text: this.$t('organization.organization'), value: 'organization.name'},
        {text: this.$t('charging-point.imei'), value: 'evse.chargingPointIdentifier'},
        {text: this.$t('city'), value: 'evse.address.city'},
        {text: this.$t('created-by'), value: 'createdBy'},
      ]
    },

    issueTypes()  {
      if(this.stats) {
        return [
          {text: this.$t('enums.issue-types.STATION_OFFLINE'), value: 'STATION_OFFLINE', count: this.stats.STATION_OFFLINE}, // 'Deconnexions de borne'
          {text: this.$t('enums.issue-types.STATION_ERROR'), value: 'STATION_ERROR', count: this.stats.STATION_ERROR}, // 'Codes d\'erreur'
          {text: this.$t('enums.issue-types.SESSION_ZERO_KWH'), value: 'SESSION_ZERO_KWH', count: this.stats.SESSION_ZERO_KWH}, // 'Sessions à 0 kWh'
          {text: this.$t('enums.issue-types.SESSION_OVER_36H'), value: 'SESSION_OVER_36H', count: this.stats.SESSION_OVER_36H}, // 'Sessions de + de 36h'
          {text: this.$t('enums.issue-types.USER_NOTIFY'), value: 'USER_NOTIFY', count: this.stats.USER_NOTIFY}, // 'Déclarés via l\'application'
          {text: this.$t('enums.issue-types.SUPERVISOR_NOTIFY'), value: 'SUPERVISOR_NOTIFY', count: this.stats.SUPERVISOR_NOTIFY}, // 'Autres incidents'
        ]
      }
      return []
    },

    statusTypes()  {
      return [
        {text: this.$t('enums.issue-statues.NEW'), value: 'NEW'},
        {text: this.$t('enums.issue-statues.OPENED'), value: 'OPENED'},
        {text: this.$t('enums.issue-statues.IN_PROGRESS'), value: 'IN_PROGRESS'},
        {text: this.$t('enums.issue-statues.PENDING'), value: 'PENDING'},
        {text: this.$t('enums.issue-statues.RESOLVED'), value: 'RESOLVED'},
        {text: this.$t('enums.issue-statues.CLOSED'), value: 'CLOSED'},
        {text: this.$t('enums.issue-statues.REJECTED'), value: 'REJECTED'},
      ]
    },

    priorityTypes()  {
      return [
        {text: this.$t('enums.issue-priorities.LOW'), value: 'LOW'},
        {text: this.$t('enums.issue-priorities.NORMAL'), value: 'NORMAL'},
        {text: this.$t('enums.issue-priorities.HIGH'), value: 'HIGH'},
        {text: this.$t('enums.issue-priorities.CRITICAL'), value: 'CRITICAL'},

      ]
    },
  },

  methods: {
    loadStats() {
      this.showLoading(true)
      let data = {
        dateFrom: this.filters.dates?.length > 0 ? this.filters.dates[0] + ' 00:00:00' : null,
        dateTo: this.filters.dates?.length > 0 ? this.filters.dates[1] + ' 23:59:59' : null,
        organizationIds: this.filters.organizationId ? [this.filters.organizationId] : [],
        statues: this.filters.statues,
        priorities: this.filters.priorities,
      };

      IssueRepository.stats(data).then(response => {
        this.stats = response
        this.showLoading(false)
      }).catch(error => {
        this.$dialog.notify.error(error.response?.data?.message ?? error.message)
        this.showLoading(false)
      })
    },

    loadData() {

      this.loading = true


      let data = {
        statues: this.filters.statues,
        priorities: this.filters.priorities,
        types: this.filters.types,
        organizationIds: this.filters.organizationId ? [this.filters.organizationId] : [],
        chargingPointId: this.filters.chargingPointId,
        dateFrom: this.filters.dates?.length > 0 ? this.filters.dates[0] + ' 00:00:00' : null,
        dateTo: this.filters.dates?.length > 0 ? this.filters.dates[1] + ' 23:59:59' : null,
      };



      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage

      if (this.options.sortBy.length > 0) {
        data.orderBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
      }


      IssueRepository.search(data).then(response => {
        this.total = response.total
        this.issues = response.items
        this.loading = false
      }).catch(error => {
        this.$dialog.notify.error(error.response?.data?.message ?? error.message)
        this.loading = false
      })
    },
  }

}
</script>

<style lang="scss">
  .issues-counter {
    & > .v-card__title {
      align-items: center;
      justify-content: center;
      text-align: center;
      color: white !important;
      height: 80px;
    }
    & > .v-card__text {
      color: white !important;
      text-align: center;
      .counter {
        margin: 30px 20px 40px 20px;
        font-size: 100px;
      }
    }
  }


</style>
