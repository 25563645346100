<template>
  <div class="issues-add  pa-10">
    <title-page icon="mdi-alert-outline">
      {{ $t('issues.add') }}
    </title-page>


    <v-breadcrumbs
        :items="[
            {text: $t('issues.issues'), to: {name: 'issues'}, exact: true},
            {text: $t('issues.add')},
        ]"
        divider=">"
    ></v-breadcrumbs>


    <v-form ref="form" @submit.prevent="save">

      <headline>{{ $t('issues.issues') }}</headline>

      <v-row>
        <v-col>
          <form-field
              type="charging-point-select"
              :label="$t('evse-name')"
              v-model="form.chargingPointId"
              :rules="[commonRules.required]"
              :col-field="9"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <form-field
              :label="$t('issues.subject')"
              v-model="form.subject"
              :rules="[commonRules.required]"
              :col-field="9"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <form-field
              :label="$t('issues.description')"
              type="wysiwyg"
              v-model="form.description"
              :rules="[commonRules.required]"
              :col-field="9"
          />

        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-right">
          <v-btn type="submit">
              {{ $t('create') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>





  </div>
</template>

<script>

import rulesMixin from "@mixins/RulesMixin";
import IssueRepository from "@repository/IssueRepository";



export default {


  data() {

    return {
      form: {
        subject: null,
        description: '',
        chargingPointId: null,
      },
    }
  },

  mixins: [
      rulesMixin,
  ],

  mounted() {
    if(!this.currentUser.permissions.includes('ISSUE_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
  },

  watch: {
    form: {
      handler() {
        this.setUnsavedChanges()
      },
      deep: true
    }
  },

  computed: {


  },

  methods: {
    save() {
      this.showLoading(true)
      if(this.$refs.form.validate()) {
        IssueRepository.create(this.form).then(result => {
          this.showLoading(false)
          this.cleanUnsavedChanges()
          this.$dialog.notify.success(this.$t('update-success'))
          this.$router.push({name: 'issues'})
        }).catch(error => {
          this.showLoading(false)
          this.$dialog.notify.error(error.response?.data?.message ?? error.message)
        })
      }
    }
  },

}
</script>

<style lang="scss">
  .issues-add {

  }


</style>
