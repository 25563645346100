<template>
  <div class="issues-details  pa-10">

    <title-page icon="mdi-alert-outline">
      {{ $t('issues.show') }}
    </title-page>


    <v-breadcrumbs
        :items="[
            {text: $t('issues.issues'), to: {name: 'issues'}, exact: true},
            {text: $t('issues.show')},
        ]"
        divider=">"
    ></v-breadcrumbs>






    <template  v-if="issue">
      <v-card>
        <v-card-title>
          {{ issueSubject(issue) }}
        </v-card-title>

        <v-card-subtitle>
          {{ $t('created-by')}}
          <template v-if="issue?.createdBy">{{ issue.createdBy.firstname }} {{ issue.createdBy.lastname }}</template>
          <template v-else>{{ $t('automation') }}</template>
          - {{ issue.createdAt|formatDate }}
        </v-card-subtitle>

        <v-card-text>
          <v-row class="issue-infos">
            <v-col>
              <v-row dense>
                <v-col cols="3" class="label">{{ $t('issues.type') }} :</v-col>
                <v-col class="value">{{ $t('enums.issue-types.' + issue.type) }}</v-col>
              </v-row>
              <v-row dense>
                <v-col cols="3" class="label">{{ $t('issues.status') }} :</v-col>
                <v-col class="value">
                  <v-chip dark :color="issueStatusColor(issue)">
                    {{ $t('enums.issue-statues.' + issue.status) }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="3" class="label">{{ $t('issues.priority') }} :</v-col>
                <v-col class="value">{{ $t('enums.issue-priorities.' + issue.priority) }}</v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row dense v-if="issue.organization">
                <v-col cols="3" class="label mt-2">{{ $t('organization.organization') }} :</v-col>
                <v-col class="value">{{ issue.organization.name }}</v-col>
              </v-row>
              <v-row dense>
                <v-col cols="3" class="label">{{ $t('charging-point.imei') }} :</v-col>
                <v-col class="value">
                  <router-link :to="{name: 'charging-points.detail', params: {id: issue.evse.chargingPointId}}" target="_blank">
                    {{ issue.evse.chargingPointIdentifier }}
                  </router-link>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="3" class="label">{{ $tc('location.location') }} :</v-col>
                <v-col class="value" v-if="issue.evse.address">{{ issue.evse.address.number }} {{ issue.evse.address.street }} - {{ issue.evse.address.city }}</v-col>
              </v-row>
              <v-row dense>
                <v-col cols="3" class="label">{{ $t('issues.model-firmware') }} :</v-col>
                <v-col class="value">{{ issue.evse.vendor }} {{ issue.evse.model }} / {{ issue.evse.firmware }}</v-col>
              </v-row>

            </v-col>
          </v-row>

          <v-divider class="my-6" />

          <template v-if="issue.chargingPointDisconnection">
            <v-row>
              <v-col>
                <div>
                  {{ $t('reports.offline.disconnected-at') }} : <b>{{ issue.chargingPointDisconnection.disconnectedAt|formatDate }}</b>
                </div>
                <div v-if="issue.chargingPointDisconnection.reconnectedAt">
                  {{ $t('reports.offline.reconnected-at') }} : <b>{{ issue.chargingPointDisconnection.reconnectedAt|formatDate }}</b>
                </div>
                <div>
                  {{ $t('duration') }} : {{ disconnectionDuration(issue.chargingPointDisconnection) }}
                </div>
              </v-col>
            </v-row>
          </template>

          <template v-if="issue.session">
            <v-row>
              <v-col>
                <div>
                  {{ $t('session-id') }} : <b>{{ issue.session.id }}</b>
                </div>
                <div>
                  {{ $t('duration') }} : <b>{{ formatDuration(issue.session.duration) }}</b>
                </div>
                <div>
                {{ $t('parking-duration') }} : <b>{{ formatDuration(issue.session.parkingDuration) }}</b>
                </div>
                <div>
                  {{ $t('energy') }} : <b>{{ issue.session.energy / 1000 }} {{ $t('kWh') }}</b>
                </div>
              </v-col>
            </v-row>
          </template>



          <template v-if="issue.chargingPointError">
            <h3>{{ $t('issues.error-code') }}</h3>
            <v-row>
              <v-col>
                <div v-if="issue.chargingPointError.code">
                  {{ $t('code') }} : <b>{{ issue.chargingPointError.code }}</b>
                </div>
                <div v-if="issue.chargingPointError.connectorId">
                  {{ $t('connectorId') }} : <b>{{ issue.chargingPointError.connectorId }}</b>
                </div>
                <div v-if="issue.chargingPointError.info">
                  {{ $t('information') }} : <b>{{ issue.chargingPointError.info }}</b>
                </div>
                <div v-if="issue.chargingPointError.vendorCode">
                  {{ $t('vendor-code') }} : <b>{{ issue.chargingPointError.vendorCode }}</b>
                </div>
                <div v-if="issue.errorRepeated > 0">
                  {{ $t('issues.error-count') }} : <b>{{ issue.errorRepeated + 1 }}</b>
                </div>
              </v-col>
            </v-row>
          </template>

          <template v-if="issue.description">
            <h3>{{ $t('issues.description') }}</h3>
            <v-row>
              <v-col>
                <div v-html="issue.description"></div>
              </v-col>
             </v-row>
          </template>

        </v-card-text>
      </v-card>

      <v-card  class="event my-6" v-for="event in issue.events" :key="event.id">

        <v-card-text>

          <div class="status" v-if="event.statusChanged">
            <i>{{ $t('issues.status-changed') }} :</i> <v-chip x-small dark :color="issueStatusColor(event)">{{ $t('enums.issue-statues.' + event.status) }}</v-chip>
          </div>

          <div class="priority" v-if="event.priorityChanged">
            <i>{{ $t('issues.priority-changed') }} :</i> <v-chip x-small>{{ $t('enums.issue-priorities.' + event.priority) }}</v-chip>
          </div>

          <v-divider class="my-6" v-if="event.statusChanged || event.priorityChanged" />

          <template v-if="event.note">
            <div class="wysiwyg-output" v-html="event.note"></div>
            <v-divider></v-divider>
          </template>
          <div class="footer">
            {{ $t('by')}}
            <template v-if="event.createdBy">{{ event.createdBy.firstname }} {{ event.createdBy.lastname }}</template>
            <template v-else>{{ $t('automation') }}</template>
              {{ event.createdAt|formatDate($t('issues.event.date-format')) }}
          </div>
        </v-card-text>
      </v-card>
    </template>


    <div class="add-note" v-if="currentUser.permissions.includes('ISSUE_WRITE') && !isClosed">
      {{ $t('issues.add-note') }}
      <v-divider class="my-3"></v-divider>
      <v-form ref="form" @submit.prevent="addNote">


        <v-row>
          <v-col>
            {{ $t('issues.comment') }}
            <wysiwyg-field
                v-if="!loading"
                v-model="eventForm.note"
                :disabled="addEventLoading"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="ml-auto">
            <v-select
                :label="$t('issues.status')"
                v-model="eventForm.status"
                :items="statuesItems"
                hide-details
                outlined
                :disabled="addEventLoading"
            ></v-select>

          </v-col>
          <v-col class="ml-auto">
            <v-select
                :label="$t('issues.priority')"
                v-model="eventForm.priority"
                :items="priorityItems"
                hide-details
                outlined
            />

          </v-col>

          <v-spacer></v-spacer>

          <v-col class="text-right">
            <v-btn type="submit" :loading="addEventLoading" :disabled="addEventLoading">
              {{ $t('add') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row>

        </v-row>
      </v-form>
    </div>

    <div class="closed-issue" v-if="isClosed">
      {{ $t('issues.issues-closed') }}<br /><br />
      <v-btn @click="openIssue" small>{{ $t('open')}}</v-btn>
    </div>


  </div>
</template>

<script>


import IssueRepository from "@repository/IssueRepository";
import RulesMixin from "@mixins/RulesMixin";
import WysiwygField from "@blocks/WysiwygField";


export default {
  components: {WysiwygField},
  data() {
    return {
      loaded: false,
      loading: false,
      changePriorityLoading: false,
      addEventLoading: false,
      issue: null,
      eventForm: {
        priority: null,
        status: null,
        note: null,
      },
    }
  },

  mixins: [
      RulesMixin,
  ],

  mounted() {
    this.loadData()
  },

  watch: {
    eventForm: {
      handler() {
        this.setUnsavedChanges()
      },
      deep: true
    }
  },

  computed: {
    priorityItems() {
      return [
        {text: this.$t('enums.issue-priorities.CRITICAL'), value: 'CRITICAL'},
        {text: this.$t('enums.issue-priorities.HIGH'), value: 'HIGH'},
        {text: this.$t('enums.issue-priorities.NORMAL'), value: 'NORMAL'},
        {text: this.$t('enums.issue-priorities.LOW'), value: 'LOW'},
      ]
    },

    statuesItems() {
      return [
        {text: this.$t('enums.issue-statues.NEW'), value: 'NEW'},
        {text: this.$t('enums.issue-statues.OPENED'), value: 'OPENED'},
        {text: this.$t('enums.issue-statues.CLOSED'), value: 'CLOSED'},
        {text: this.$t('enums.issue-statues.IN_PROGRESS'), value: 'IN_PROGRESS'},
        {text: this.$t('enums.issue-statues.PENDING'), value: 'PENDING'},
        {text: this.$t('enums.issue-statues.REJECTED'), value: 'REJECTED'},
        {text: this.$t('enums.issue-statues.RESOLVED'), value: 'RESOLVED'},
      ]
    },

    isClosed() {
      return this.issue?.status === 'CLOSED' || this.issue?.status === 'REJECTED' || this.issue?.status === 'RESOLVED'
    }
  },

  methods: {
    loadData() {
      this.showLoading(true)
      this.loading = true
      this.$nextTick(() => {
        this.loading = false
      })
      IssueRepository.get(this.$route.params.id).then(issue => {
        this.issue = issue
        this.eventForm.priority = issue.priority ?? 'NORMAL'
        this.eventForm.status = (issue.status === 'NEW' ? 'OPENED' : issue.status)


        let lastPriority =  "NORMAL"
        let lastStatus =  "NEW"
        this.issue.events.forEach(e => {

          if(e.note === 'station-reconnected') {
            e.note = this.$t('issues.station-reconnected')
          }

          e.priorityChanged = e.priority !== lastPriority
          e.statusChanged = e.status !== lastStatus
          lastPriority =  e.priority
          lastStatus =  e.status
        })
        this.$nextTick(() => {
          this.loaded = true
          this.$nextTick(() => {
            this.cleanUnsavedChanges()
          })
        })
        this.showLoading(false)

      }).catch(error => {
        this.showLoading(false)
        this.$dialog.notify.error(error.response?.data?.message ?? error.message)
      })
    },


    addNote() {

      this.showLoading(true)
      this.addEventLoading = true
      IssueRepository.addEvent(this.issue.id, this.eventForm).then(result => {
        this.addEventLoading = false
        this.eventForm = {
          priority: this.issue.priority,
          status: this.issue.status,
          note: null,
        }
        this.loadData()
        this.showLoading(false)
      }).catch(error => {
        this.$dialog.notify.error(error.response?.data?.message ?? error.message)
        this.addEventLoading = false
        this.showLoading(false)
      })
    },

    openIssue() {
      this.issue.status = 'OPENED'
      this.eventForm.status = 'OPENED'
    }
  },

}
</script>

<style lang="scss">
.issues-details {
  .issue-infos {
    .label {
      font-weight: 100;
    }
    .value {
      font-weight: bold;
    }
  }




  .event {
    margin: 40px 0 0 100px;
    .priority {
      font-size: 12px;
    }
    .status {
      font-size: 12px;
    }
  }

  .closed-issue {
    margin: 40px 0 0 100px;
    text-align: center;
    color: #777676;
  }

  .add-note {
    margin: 40px 0 0 100px;
  }
}


</style>
